import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.99);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeInTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 30%;
  right: 0;
  margin-right: 7%;
  
  @media (max-width: 1200px) {
    text-align: center;
    width: 100%;
    position: relative;
    margin-right: 0%;
    text-align: center;
  }

  .logo {
    filter: invert(1);
    margin: 0px;
    width: 100%;
    max-width: 600px;
    margin-top: -5rem;
    margin-bottom: 5rem;

    opacity: 0;
    animation: ${fadeIn} 1s ease-in-out forwards 0.4s;

    @media (max-width: 1200px){
      margin-top: 5rem;
      max-width: 300px;
    }
  }
`;

export const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  justify-content: flex-end;
  display: flex;
  right: 0;
  top: 0;
  font-weight: normal;
  top: 60%;
  color: #fff;
  margin-top: -4rem;

  opacity: 0;
  animation: ${fadeInTop} 0.5s ease-in-out forwards 0.5s;
`;

export const DescriptionContainer = styled.div`
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: 700px;
  margin: 0px;
  
  opacity: 0;
  animation: ${fadeInTop} 0.5s ease-in-out forwards 0.6s;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;

    p {
      text-align: center;
      font-size: 2rem;
    }
  }
`;

export const Description = styled.p`
  color: #fff;
  font-weight: bolder;
  text-align: right;
  font-size: 3rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  right: 0;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  top: 100%;

  @media (max-width: 1200px){
    justify-content: center;
    padding-top: 4rem;
  }
  
  opacity: 0;
  animation: ${fadeInTop} 0.5s ease-in-out forwards 0.7s;
`;
