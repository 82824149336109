import React from 'react';

import { Container } from "./style";

export const Star = () => (
  <Container>
    <svg
      id="homeStarSVG"
      width="522"
      height="779"
      viewBox="0 0 522 779"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="StarSketch"
        d="M159.857 120.918C191.713 78.2099 259.608 101.217 258.939 154.492L257.438 274.037C257.139 297.863 272.216 319.171 294.783 326.818L408.013 365.187C458.474 382.286 457.574 453.968 406.7 469.795L292.542 505.309C269.79 512.387 254.183 533.31 253.884 557.136L252.383 676.681C251.715 729.956 183.263 751.251 152.489 707.757L83.437 610.161C69.6746 590.71 44.9527 582.333 22.2008 589.411L-91.9569 624.925C-142.832 640.752 -184.237 582.231 -152.381 539.523L-80.9006 443.692C-66.6543 424.593 -66.3265 398.492 -80.0889 379.041L-149.141 281.445C-179.915 237.951 -137.053 180.488 -86.5918 197.587L26.6383 235.956C49.2053 243.603 74.1298 235.849 88.3761 216.749L159.857 120.918Z"
        fill="#3DBFEA"
      />
    </svg>
  </Container>
);