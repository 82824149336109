import styled from 'styled-components';

export const AboutUsContainer = styled.div`
  z-index: 10;
  position: relative;
  margin-top: -34rem;
  h1 {
    font-family: 'Rufina bold';
    text-align: center;
    font-size: 93pt;
    margin-top: 3rem;
    color: ${(props) => props.theme.palette.yellow._100};
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 70pt;
    }

    .aboutUsImg {
      margin-top: 5rem;
      width: 100%;
    }
  }
`;

export const Title = styled.h1``;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;

  p {
    line-height: 50px;
    font-family: 'Rufina';
    font-size: 20pt;
    text-justify: inter-word;
  }
`;

export const Content = styled.h2``;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 20%;
    margin-top: 5rem;
  }
`;

export const ContainerAbout = styled.div`
  margin: 0 0 0 0;
  padding: 0;
  padding-top: 30rem;
  padding-bottom: 10rem;

  @media (max-width: 1200px) {
    padding-top: 26rem;
  }
`;

export const Container = styled.div`
  .bg {
    top: 0;
    position: absolute;
    background: #1f1e1e;
    height: 480px;
    width: 100%;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 20%;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const Content2 = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  #wave {
    width: 100%;
    margin-bottom: -10px;
  }

  img {
    width: 100%;
  }

  h1 {
    line-height: 50px;
    font-family: 'Rufina';
    font-size: 32pt;
    font-weight: bold;
    text-justify: inter-word;

    @media (max-width: 1000px) {
      font-size: 24pt;
    }
  }

  h1,
  p {
    padding: 0 16px;
  }
`;
