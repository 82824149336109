export const links = {
	app: 'https://avaliei-app.web.app',
	rank: 'https://avaliei-rank.web.app',
	dashboard: 'https://avaliei-admin.web.app',
	test: 'https://avaliei-app.web.app/worker/vuyUDXcigc'
};

export const qrCode = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${links.test}`;

export const phoneNumber = '+55 12 936180956';

export const email = 'contato@avaliei.app';

export const zapzap = 'https://wa.me/message/' + phoneNumber.replace(/[\s-]/g, '');
