import React from "react";

import HomeStarPNG from "../../../assets/img/femaleStar.png";

import { Container } from "./style";
import { Star } from "./Sketch/Star";

const HomeStar = () => {
  return (
    <>
      <Container>
        <img src={HomeStarPNG} alt="home star png" />
        <Star />
      </Container>
    </>
  );
};

export default HomeStar;
