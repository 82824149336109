import React from "react";

import { Container, PhoneContainer, Title, ContentContainer } from "./styles";

import Fade from "react-reveal/Fade";

import phoneContainerHomeIMG from "../../assets/svg/phoneContainer_home.svg";
import phoneContainerWorkerIMG from "../../assets/img/phoneContainer_worker.png";

import { AboutWave } from "../wave/AboutWave";

const Description = () => {
  return (
    <Container>
      <AboutWave />
      <ContentContainer>
        <Fade bottom cascade>
          <PhoneContainer>
            <img
              src={phoneContainerHomeIMG}
              className="phoneHome"
              alt="phoneContainer"
            />
            <img
              src={phoneContainerWorkerIMG}
              className="phoneWorker"
              alt="phoneContainerWorkerIMG"
            />
          </PhoneContainer>
        </Fade>
        <Title>
          <Fade left cascade>
            <div>Avaliações</div>
            <div>simples</div>
            <div>rápidas</div>
            <div>e seguras !</div>
          </Fade>
        </Title>
      </ContentContainer>
    </Container>
  );
};

export default Description;
