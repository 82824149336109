import React from "react";

import { ButtonContainer, Container } from "./style";

const Button = ({ children, negative, click, link }) => {
  const handleRedirect = () => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  return (
    <Container>
      <ButtonContainer
        onClick={link ? handleRedirect : click}
        className={negative === true && "negative"}
      >
        {children}
      </ButtonContainer>
    </Container>
  );
};

export default Button;
