import React from "react";

import Fade from "react-reveal/Fade";

import Button from "../button/Button";

import {
  ContainerImg,
  CardContainer,
  Container,
  LeftContainer,
  MobileImageContainer,
} from "./style";

import card1 from "../../assets/img/card-1-min.png";
import card2 from "../../assets/img/card-2-min.png";
import card3 from "../../assets/img/card-3-min.png";

import rankGroup from "../../assets/img/rankGroup.png";
import rankinIcon from "../../assets/icon/rankinIcon.svg";
import { links } from '../../utils';

const Ranking = () => {
  const screenWidth = window.screen.width;

  return (
    <Container>
      {screenWidth > 1200 && (
        <ContainerImg>
          <CardContainer>
            <Fade bottom cascade>
              <div>
                <img className="card-1" src={card1} alt="card1" />
                <img className="card-2" src={card2} alt="card2" />
                <img className="card-3" src={card3} alt="card3" />
              </div>
            </Fade>
          </CardContainer>
        </ContainerImg>
      )}

      {screenWidth < 1200 && (
        <MobileImageContainer>
          <Fade bottom cascade>
            <img src={rankGroup} alt="cardGroup" />
          </Fade>
        </MobileImageContainer>
      )}

      <LeftContainer>
        <Fade>
          <img src={rankinIcon} alt="rankinIcon" />
        </Fade>
        <Fade cascade>
          <h1>Ranking</h1>
          <p>
            Além das avaliações, o avaliei conta com um ranking gamificado <br /> que possibilita, de forma simples e automatizada, bonificar o bom atendimento desse funcionário.
          </p>
          <Button link={links.rank}>Saiba mais</Button>
        </Fade>
      </LeftContainer>
    </Container>
  );
};

export default Ranking;
