import React from "react";

import Footer from "../../components/footer/Footer";

import { links } from "../../utils";
import { Container, ContainerAbout } from "./style";

const AboutUs = () => {
  return (
    <Container>
      <div className="bg"></div>
      <ContainerAbout>
        <div>
          <h1>Teste o nosso sistema !</h1>
          <h2>Scaneie o QR code abaixo e nos avalie</h2>
          <h3>
            Caso não possua um leitor de Qr Code você pode usar o nosso site{" "}
            <a href={links.app} target="_blank" rel="noreferrer">
              clicando aqui
            </a>
          </h3>
        </div>
        <img src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${links.test}`} alt="" />
      </ContainerAbout>
      <Footer />
    </Container >
  );
};

export default AboutUs;
