import React from 'react';

import { AboutWave } from '../../components/wave/AboutWave';
import { Text } from './Text';

import Alysson from '../../assets/img/Alysson.png';
import Guilherme from '../../assets/img/Guilherme.png';

import Footer from '../../components/footer/Footer';

import { Title, AboutUsContainer, ContentContainer, ImageContainer, ContainerAbout, Container, ImgContainer } from './style';

const AboutUs = ({ id }) => {
  const screenWidth = window.screen.width;

  return (
    <Container>
      <div className='bg'></div>
      <ContainerAbout>
        <AboutWave />
        <AboutUsContainer id={id}>
          <Title>Nossa história</Title>
          {/* <ImageContainer>
            {screenWidth > 1200 && (
              <ImgContainer>
                <img
                  className='aboutUsImg'
                  src={Alysson}
                  alt='imagem da equipe'
                />
                <img
                  className='aboutUsImg'
                  src={Guilherme}
                  alt='imagem da equipe'
                />
              </ImgContainer>
            )}
            {screenWidth < 1200 && (
              <ImgContainer>
                <img
                  className='aboutUsImg'
                  src={Alysson}
                  alt='imagem da equipe'
                />
                <img
                  className='aboutUsImg'
                  src={Guilherme}
                  alt='imagem da equipe'
                />
              </ImgContainer>
            )}
          </ImageContainer> */}
        </AboutUsContainer>
        <ContentContainer>
          <Text />
        </ContentContainer>
      </ContainerAbout>
      <Footer />
    </Container>
  );
};

export default AboutUs;
