import styled, { keyframes } from "styled-components";

export const time = "0.5s";
export const animationTime = "20s";
export const animation_transparent = "0.2";

export const caseTransition = keyframes`  
  0% {
    opacity: ${animation_transparent};
    transform: translateX(0px);
  }

  25% {
    opacity: ${animation_transparent};
    transform: translateX(100px) translateY(-10px);
  }

  50% {
    opacity: ${animation_transparent};
    transform: translateX(-100px) translateY(-10px);
  }
`;

export const Case = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15rem;


  img {
    margin-left: 3rem;
    width: 100%;
    height: 100%;
    max-width: 150px;
    max-height: 150px;
    opacity: 0.5;
    transition: ${time};
    z-index: 2;

    @media (max-width: 1200px){
      margin-left: 0;
    }  
  
  }

  .case-1 {
    &:hover {
      opacity: 1;
    }
  }

  .case-2 {
    &:hover {
      opacity: 1;
    }
  }

  .case-3 {
    &:hover {
      opacity: 1;
    }
  }
`;

export const RealCases = styled.div``;

export const AnimationContainer = styled.div`
  opacity: ${animation_transparent};
  position: absolute;
  margin: 0px;
  align-items: center;
  display: flex;
  z-index: 1;
  animation-name: ${caseTransition};
  animation-duration: ${animationTime};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

export const AnimateCases = styled.div`
  .animateCases-2 {
    animation-delay: 1s;
  }
  
  .petrobras{
    animation-delay: 2.5s;
  }
`;
