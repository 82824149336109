import React from 'react';

import GlobalStyle from './style/global';
import { ThemeProvider } from 'styled-components';
import { themes } from './style/themes';
import Routes from './routes';

function App() {
  return (
    <ThemeProvider theme={themes}>
      <GlobalStyle />
      <>
        <Routes />
      </>
    </ThemeProvider>
  );
}

export default App;
