import React from 'react'
import Wave from 'react-wavify'

import { Container } from "./style"

export const AboutWave = () => (
  <Container>
    <Wave fill='#1f1e1e'
      paused={false}
      options={{
        height: 50,
        amplitude: 50,
        speed: 0.20,
        points: 4
      }}
    />
  </Container>
)
