/* eslint-disable no-unused-vars */
import React from "react";

import Cases from "../../components/cases/Cases";
import HomeStar from "../../components/home/homeStar/HomeStar";
import LeftHome from "../../components/home/leftHome/leftHome";

import Description from "../../components/description/Description";
import Ranking from "../../components/ranking/Ranking";
import Dashboard from "../../components/dashboard/Dashboard";
import Footer from "../../components/footer/Footer";

import { HomeContainer, Wrapper } from "./style";

const Home = () => {
  return (
    <>
      <HomeContainer id={"home"}>
        <HomeStar />
        <LeftHome />
        <Cases />
      </HomeContainer>

      <Description />

      <Wrapper id={"rank"} />

      <Ranking />

      <Wrapper id={"dashboard"} />

      <Dashboard />

      <Wrapper />

      <Footer />
    </>
  );
};

export default Home;
