import React, { useState } from "react";
import { useEffect } from "react";

import Logo from "./logo/Logo";

import { Link } from "react-router-dom";

import { Container, ItemsContainer, List, Item } from "./style";
import { links } from '../../utils';

export const MainHeader = () => {
  const [active, setActive] = useState("/");
  const [scroll, setScroll] = useState(false);

  const handleActive = (e) => {
    setActive(e);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setScroll(window.pageYOffset > 200)
      );
    }
  }, []);

  return (
    <Container
      scroll={scroll}
      className={`main header ${scroll ? "small" : ""}`}
    >
      <Logo setActive={setActive} className="logo" />
      <ItemsContainer className="itemsContainer">
        <List scroll={scroll}>
          <Item
            scroll={scroll}
            className={active === "/sobre" && "active"}
            onClick={() => handleActive("/sobre")}
          >
            <Link
              className="about"
              style={{ color: "inherit", textDecoration: "inherit" }}
              to="/sobre"
            >
              Sobre-nós
            </Link>
          </Item>
          <Item scroll={scroll} onClick={() => handleActive("/")} className={active === "/" && "active"}>
            <Link
              className="home"
              style={{ color: "inherit", textDecoration: "inherit" }}
              to={"/"}
            >
              Home
            </Link>
          </Item>
          <Item scroll={scroll}>
            <a
              className="rank"
              style={{ color: "inherit", textDecoration: "inherit" }}
              href={links.rank}
            >
              Rank
            </a>
          </Item>
          <Item scroll={scroll}>
            <a
              className="dashboard"
              style={{ color: "inherit", textDecoration: "inherit" }}
              href={links.dashboard}
            >
              Dashboard
            </a>
          </Item>
          <Item scroll={scroll}>
            <Link
              className="ver"
              style={{ color: "inherit", textDecoration: "inherit" }}
              to={"/ver"}
            >
              Faça um teste
            </Link>
          </Item>
        </List>
      </ItemsContainer>
    </Container>
  );
};
