import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    font-family: 'Roboto', sans-serif;
    word-wrap: break-word !important;
    word-break: break-word !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    h1,h2,h3,p{
      overflow-wrap: break-word;
      word-break: break-word !important;
    }

  &::-webkit-scrollbar {
    width: 3px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.yellow.main};
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.black.main};
  }

  *{
    ::selection{
      background: #00b2f0;
      color: #f5f5f5;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    }
  }

   html { 
    text-shadow: rgba(0,0,0,.01) 0 0 1px;
    -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;

    @media (max-width: 1200px){
      font-size: 85.75%;
    }
     @media (max-width: 1080px) {
       font-size: 93.75%;
     }
     @media (max-width: 720px) {
       font-size: 87.5%;
     }
     button {
       cursor: pointer;
     }
     [disabled] {
       opacity: 0.5;
       cursor: not-allowed;
     }
    }
}
`;

export default GlobalStyle;
