import React from "react";

import LogoLetter from "../../../assets/svg/logo.svg";
import Button from "../../button/Button";

import { links, zapzap } from '../../../utils';
import {
  Container,
  Title,
  DescriptionContainer,
  Description,
  ButtonsContainer,
} from "./style";

const LeftHome = () => {
  return (
    <Container>
      <img id="#" src={LogoLetter} className="logo" alt="logo letter" />
      <Title>Avalie seu atendimento favorito !</Title>
      <DescriptionContainer>
        <Description>
          O Avaliei ajuda a sua empresa a melhorar a relação entre cliente e
          funcionário
        </Description>
      </DescriptionContainer>
      <ButtonsContainer>
        <a
          href={zapzap}
          target="blank"
          rel="noreferrer"
        >
          <Button>Contate-nos</Button>
        </a>
        <a
          href={`${links.app}${window.location.pathname}`}
          target="blank"
          rel="noreferrer"
        >
          <Button negative>Avalie agora !</Button>
        </a>
      </ButtonsContainer>
    </Container>
  );
};

export default LeftHome;
