import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 5rem;

  @media (max-width: 1200px) {
    flex-direction: column;
    margin: 0 1rem;
  }
`;

export const ContainerImg = styled.div`
  display: block;
  margin-top: 10rem;
  height: 560px;
  position: relative;
  width: 600px;
  transform: scale(0.8);
`;

export const CardContainer = styled.div`
  img {
    width: 300px;
    display: flex;
  }

  .card-1 {
    position: absolute;
    z-index: 2;
    left: 150px;
  }

  .card-2 {
    position: absolute;
    z-index: 1;
    top: -100px;
    left: 0px;
  }

  .card-3 {
    position: absolute;
    z-index: 1;
    top: 100px;
    right: 0px;
  }
`;

export const LeftContainer = styled.div`
  max-width: 450px;
  margin-top: 5rem;
  
  img {
    margin-left: -10px;
  }

  h1 {
    color: #2b3347;
    font-family: "Roboto", sans-serif;
    font-size: 3rem;
  }

  p {
    color: #2b3347;
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    width: 100%;
  }

  button {
    margin: 1rem 0 0 0;
    padding: 5px 20px;
    font-size: 1.2rem;
  }
`;

export const MobileImageContainer = styled.div`
  margin: 0px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  
  img {
    width: 350px;
    height: 100%;
  }
`;
