import styled, { keyframes } from "styled-components";

export const rollingAnimation = keyframes`
  0% {
    transform: rotate(0deg) scale(0.9);
    opacity: 1;
  }
  50% {  
    transform: rotate(20deg) scale(0.9);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg) scale(0.9);
    opacity: 1;
  }
`;

export const fadeAnimation = keyframes`
  0% {
    transform: translateX(-5%);
    filter: blur(5px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    filter: blur(0);
    opacity: 1;
  }
`;

export const Container = styled.div`
  animation-name: ${fadeAnimation};
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  transform-origin: 6% center;
  animation-fill-mode: forwards;
  opacity: 0;
  
  img {
    opacity: 0;
    position: absolute;
    z-index: 10;
    top: -10%;
    left: -25%;
    transform: scale(0.9);
    transform-origin: center, center;

    animation-name: ${rollingAnimation};
    animation-duration: 100s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    transform-origin: 6% center;
  }

  @media (min-width: 1700px) {
    img {
      left: -10%;
    }
  }


  @media (max-width: 1200px){
    display: none;
  }
`;
