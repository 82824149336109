import styled, { keyframes } from "styled-components";

export const rollingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {  
    transform: rotate(360deg);
  }
`;

export const fadeAnimation = keyframes`
  0% {
    transform: translateX(-5%);
    filter: blur(5px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    filter: blur(0);
    opacity: 1;
  }
`;

export const Container = styled.div`
  animation-name: ${fadeAnimation};
  animation-duration: .7s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;

  svg#homeStarSVG {
    overflow: visible;
    margin-top: 0px;

    animation-name: ${rollingAnimation};
    animation-duration: 100s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: 6% center;
  }

  @media (min-width: 1700px) {
    svg#homeStarSVG {
      transform: scale(1.2);
    }
  }
`;
