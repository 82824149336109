import React from "react";

import { useHistory } from "react-router";

import { Container } from "./style";

const Logo = ({ setActive }) => {
  const history = useHistory();

  const handleHistory = () => {
    history.push("/");
    setActive('/');
  };

  return (
    <Container onClick={handleHistory} className="logoContainer">
      <svg
        id="logoSVG"
        width="59"
        height="56"
        viewBox="0 0 59 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="star">
          <path
            id="Vector"
            d="M35.0719 15.97L35.4242 16.788L36.311 16.8703L50.9574 18.2287L39.9066 27.9365L39.2375 28.5244L39.4333 29.3933L42.6674 43.7427L30.0198 36.2327L29.2539 35.778L28.4881 36.2327L15.8404 43.7427L19.0745 29.3932L19.2703 28.5244L18.6011 27.9365L7.55043 18.2287L22.1968 16.8702L23.0837 16.7879L23.436 15.9699L29.2539 2.46022L35.0719 15.97ZM51.594 18.2877C51.5939 18.2877 51.5938 18.2877 51.5938 18.2877L51.594 18.2877Z"
            stroke="#FFCD61"
            strokeWidth="3"
          />
          <g id="pins">
            <path
              id="Vector_2"
              d="M13.9395 4.49406C12.8326 3.38749 12.0106 2.51735 11.7465 2.15045L11.7464 2.15035C11.614 1.96645 11.6557 1.70998 11.8397 1.5775L11.8398 1.57744C12.0236 1.44512 12.2801 1.48673 12.4127 1.67081L13.9395 4.49406ZM13.9395 4.49406C13.2413 3.0933 12.6769 2.03776 12.4127 1.67084L13.9395 4.49406Z"
              stroke="#FFCD61"
              strokeWidth="3"
            />
            <path
              id="Vector_3"
              d="M44.5678 4.49388C45.266 3.09321 45.8304 2.03775 46.0945 1.67084L44.5678 4.49388ZM44.5678 4.49388C45.6746 3.38743 46.4966 2.51731 46.7608 2.15035L44.5678 4.49388ZM46.6675 1.5775L46.6674 1.57745C46.4836 1.44512 46.2271 1.48673 46.0945 1.67081L46.6675 1.5775ZM46.6675 1.5775C46.8515 1.70997 46.8932 1.96641 46.7608 2.1503L46.6675 1.5775Z"
              stroke="#FFCD61"
              strokeWidth="2"
            />
            <path
              id="Vector_4"
              d="M53.5703 33.3578C55.117 33.5974 56.2942 33.8145 56.724 33.9546L53.5703 33.3578ZM53.5703 33.3578C54.9609 34.0762 56.0397 34.5949 56.4694 34.7351L56.4695 34.7351C56.6849 34.8054 56.9166 34.6877 56.9869 34.4721C57.0572 34.2565 56.9395 34.0249 56.7241 33.9546L53.5703 33.3578Z"
              stroke="#FFCD61"
              strokeWidth="3"
            />
            <path
              id="Vector_5"
              d="M4.93735 33.3578C3.39067 33.5974 2.21351 33.8145 1.78366 33.9546L4.93735 33.3578ZM4.93735 33.3578C3.54678 34.0762 2.46803 34.5949 2.03824 34.7351L2.0382 34.7351C1.82273 34.8054 1.59107 34.6877 1.52076 34.4721C1.45044 34.2565 1.56816 34.0249 1.78361 33.9546L4.93735 33.3578Z"
              stroke="#FFCD61"
              strokeWidth="3"
            />
            <path
              id="Vector_6"
              d="M29.3124 54.5H29.3122C29.0857 54.5 28.9019 54.3163 28.9019 54.0896C28.9019 53.6375 29.0605 52.451 29.3123 50.9062C29.5641 52.451 29.7228 53.6375 29.7228 54.0896C29.7228 54.3162 29.539 54.5 29.3124 54.5Z"
              stroke="#FFCD61"
              strokeWidth="3"
            />
          </g>
        </g>
      </svg>
    </Container>
  );
};

export default Logo;
