import styled from "styled-components";

export const HomeContainer = styled.div`
  background-color: ${(props) => props.theme.palette.black.main};
  height: 100%;
  padding-bottom: 10rem;
  margin: 0px;
  width: 100%;
`;


export const Wrapper = styled.div`
  margin: 20rem 0;
`;
