import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10rem;

  @media (max-width: 1200px) {
    flex-direction: column;
    margin: 0 1rem;

    .notebook {
      max-width: 300px;
      margin-bottom: 3rem;
    }
  }

  .first {
    max-width: 500px;
  }

  .notebook {
    width: 400px;
  }

  h1 {
    color: #2b3347;
    font-family: "Roboto", sans-serif;
    font-size: 3rem;
  }

  h2 {
    color: #2b3347;
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
  }

  button {
    margin: 1rem 0 0 0;
    padding: 5px 20px;
    font-size: 1.2rem;
  }
`;
