import styled from "styled-components";

export const AboutUsContainer = styled.div`
  z-index: 10;
  position: relative;
  h1 {
    font-family: "Rufina bold";
    text-align: center;
    font-size: 93pt;
    margin-top: 3rem;
    color: ${(props) => props.theme.palette.yellow._100};
  }

  @media (max-width: 1200px) {
   
    h1 {
      font-size: 70pt;
    }

    .aboutUsImg {
      width: 100%;
    }
  }
`;

export const Title = styled.h1``;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;

  p {
    line-height: 50px;
    font-family: "Rufina";
    font-size: 20pt;
    margin: 2rem 5rem;
    text-justify: inter-word;

    @media (max-width: 1200px) {
      margin: 2rem 1rem;
    }
  }
`;

export const Content = styled.h2``;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerAbout = styled.div`
  margin: 0px 0 0 0;
  padding: 20px;
  padding-top: 20rem;
  padding-bottom: 10rem;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  img {
    width: 100%;
    max-width: 300px;
  }

  div {
    line-height: 20px;
  }

  @media (max-width: 1200px) {
    padding-top: 26rem;
  }
`;

export const Container = styled.div`
  .bg {
    top: 0;
    position: absolute;
    background: #1f1e1e;
    height: 200px;
    width: 100%;
  }
`;
