import React from "react";
import { Route, BrowserRouter } from "react-router-dom";

import Home from "./pages/home/Home";
import Aboutus from "./pages/aboutus/Aboutus";
import See from "./pages/See/See";

import { MainHeader } from "./components/header/Header";

import { links } from "./utils";

const Routes = () => {
  if (window.location.pathname.includes("/worker")) {
    window.location.replace(links.app + window.location.pathname);
  }

  if (window.location.pathname.includes("/teste" || "/test")) {
    window.location.replace(links.app + "/worker/vuyUDXcigc");
  }

  return (
    <BrowserRouter>
      <MainHeader />
      <Route component={Home} path="/" exact />
      <Route component={Aboutus} path="/sobre" />
      <Route component={See} path="/ver" />
    </BrowserRouter>
  );
};

export default Routes;
