import styled from "styled-components";

const animationTime = "0.3s";

export const Container = styled.div`
  z-index: 100;
  margin-top: 0.5rem;
  margin-left: 1rem;
  transform-origin: center center;

  
  svg#logoSVG {
    width: 40px;
    z-index: 10;
    overflow: visible !important;
    cursor: pointer;
    transform-origin: center center;
    transition: ${animationTime};
    #Vector {
      overflow: visible !important;
      transition: ${animationTime};
      opacity: 1;
      fill: transparent;
      transform-origin: center center;
    }

    #pins {
      border-radius: 1000px;
      overflow: visible !important;
      transition: ${animationTime};
      transform: scale(0);
      opacity: 0;
      transform-origin: center center;
    }

    &:hover {
      transform: rotate(90deg);
      transform-origin: center, center;
      #Vector {
        opacity: 1;
        fill: #ffcd61;
        filter: contrast(1);
        transform-origin: center, center;
      }
      #pins {
        opacity: 1;
        transform: scale(1);
        transform-origin: center center;
      }
    }

    .selected {
      #Vector {
        transform-origin: center center;
        opacity: 1;
        fill: #ffcd61;
      }
      #star {
        opacity: 1;
        transform-origin: center center;
        transform: scale(1);
      }
    }
  }
`;
