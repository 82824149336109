import React from "react";

import Br from "../../assets/cases/br.png";
import Economico from "../../assets/cases/economico.png";

import { Case, RealCases, AnimationContainer, AnimateCases } from "./style";

const Cases = () => (
  <Case className="caseContainer">
    <RealCases className="realCases">
      <img className="case-1" src={Br} alt="petrobrás" />
      <img className="case-3" src={Economico} alt="economico" />
    </RealCases>

    <AnimationContainer className="animationContainer">
      <AnimateCases className="animateCases">
        <img src={Br} alt="petrobrás" />
        <img src={Economico} alt="economico" />
      </AnimateCases>

      <AnimateCases className="animateCases-2">
        <img src={Br} alt="petrobrás" className="petrobras" />
        <img src={Economico} alt="economico" />
      </AnimateCases>
    </AnimationContainer>
  </Case>
);

export default Cases;
