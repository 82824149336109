import styled, { keyframes } from "styled-components";

const fadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const PhoneContainer = styled.div`
  display: flex;
  position: relative;
  margin: -10rem 0;

  @media (max-width: 1200px) {
    margin: 0;
    justify-content: center;
  }

  img {
    width: 100%;
    max-width: 350px;
    
    @media (max-width: 1200px) {
      max-width: 250px;
    }
  }

  .phoneWorker {
    opacity: 0;
    animation-name: ${fadeAnimation};
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.8s;

    position: absolute;
    left: 52%;
    top: -10%;

    @media (max-width: 1200px) {
      left: 25%;
      top: -25%;
    }
  }

  .phoneHome {
    opacity: 0;
    animation-name: ${fadeAnimation};
    animation-duration: 0.9s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    animation-delay: 0.2s;

    @media (max-width: 1200px) {
      margin-right: 5rem;
    }
  }
`;

export const Title = styled.h1`
  width: 400px;
  font-size: 5rem;
  color: ${(props) => props.theme.palette.blue._300};

  @media (max-width: 1200px) {
    width: 100%;
    font-size: 3.8rem;
  }
`;

export const ContentContainer = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 0 10vw;

  @media (max-width: 1200px) {
    /* width: 100%; */
    flex-direction: column;
  }
`;
