import React from 'react';

import Fade from 'react-reveal/Fade';
import { Content2 } from './style';

import postos from '../../assets/img/postos_de_combustivel.png';
import rodrigo from '../../assets/img/rodrigo.png';
import Wave from 'react-wavify';

export const Text = () => {
  return (
    <>
      <Fade
        bottom
        cascade>
        <Content2>
          <div>
            <h1>Hacking.rio:</h1>
            <p>
              A história do Avaliei tem suas raízes na participação no evento Hacking.rio, um marco significativo em nossa jornada. Ao ingressarmos como iniciantes, fomos acolhidos pela comunidade e
              orientados por mentores experientes, que nos introduziu ao cluster de Distribuição e Conveniência. Neste ambiente inspirador, nutrimos ideias e entusiasmo, iniciando o
              processo de desenvolvimento que culminou em nossa vitória, conquistando o primeiro lugar no cluster e o quinto lugar geral no evento. Essa experiência gratificante fortaleceu nossa
              determinação e nos impulsionou para além de nossos objetivos iniciais
            </p>
          </div>

          <div>
            <h1>Incubação:</h1>
            <p>
              Após o Hacking.rio, estabelecemos uma parceria contínua com a BR Distribuidora conhecida atualmente como Vibra, entrando em um período de incubação. Mesmo diante dos desafios impostos
              pela pandemia, mantivemos uma colaboração ativa, reunindo-nos regularmente com internos da BR para discutir avanços e aprimoramentos, permitindo-nos evoluir o projeto em um contexto
              desafiador.
            </p>
          </div>

          <div>
            <h1>Pesquisa de Campo:</h1>
            <p>
              Após um ano de intenso trabalho e adaptações às restrições da pandemia, chegou o momento crucial de testar e validar o Avaliei. Em colaboração com a Distribuidora BR, selecionamos quatro
              postos com alto volume de atendimento para um teste piloto de um mês: Líder 1, Líder 2, Grafitti e Moto Mercantil.
            </p>
          </div>

          <img
            src={rodrigo}
            alt='teste'
          />

          <img
            src={postos}
            alt='teste'
          />

          <div>
            <h1>Objetivo:</h1>
            <p>
              Atualmente o Avaliei segue focado em melhorar a qualidade do atendimento brasileiro através do fornecimento de métricas do cliente, acreditamos que tratar melhor o cliente é a chave para
              o empreendedor prosperar no cenário atual
            </p>
          </div>
        </Content2>
      </Fade>
    </>
  );
};
