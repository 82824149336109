import React from "react";

import Wave from "react-wavify";

import { Container, FooterContaienr } from "./style";

import logo from "../../assets/img/avaliei.png";
import { phoneNumber, email } from "../../utils";

const mailto = `mailto:${email}`
const tel = `tel:${phoneNumber}`

export const Footer = () => {
  return (
    <FooterContaienr className="footer">
      <Wave
        className="wave"
        fill="#1f1e1e"
        paused={false}
        options={{
          height: 25,
          amplitude: 30,
          speed: 0.2,
          points: 4,
        }}
      />
      <Container>
        <div>
          <img className="logo" src={logo} alt="logo" />
        </div>
        <div className="contact">
          <h1>Contato</h1>
          <p>Telefone: <a href={tel}>{phoneNumber}</a></p>
          <p>Email: <a href={mailto}>{email}</a></p>
        </div>
        <div className="right">
          <p>&copy; 2021 Avaliei Group</p>
          <p>All rights reserved</p>
        </div>
      </Container>
    </FooterContaienr >
  );
};

export default Footer;
