import styled from "styled-components";

export const time = "0.28s";

export const Container = styled.div`
  .negative {
    color: ${(props) => props.theme.palette.blue._200};
    background: none;
    border: 5px solid ${(props) => props.theme.palette.blue.main};

    &:hover {
      box-shadow: 5px 6px 15px 0px ${(props) => props.theme.palette.blue._600};
      transform: translateY(-10px);
    }
  }
`;

export const ButtonContainer = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.4rem;
  transition: ${time};
  margin-left: 1rem;
  border-radius: 8px;
  font-weight: bolder;
  padding: 10px 2.5rem;
  border: 5px solid ${(props) => props.theme.palette.blue.main};
  color: ${(props) => props.theme.palette.white.main};
  background: ${(props) => props.theme.palette.blue.main};
  outline: none;

  &:hover {
    outline: none;
    box-shadow: 5px 6px 15px 0px ${(props) => props.theme.palette.blue._600};
    transform: translateY(-10px);
  }

  @media (max-width: 1200px) {
    font-size: 1rem;
    padding: 10px 2rem;
    outline: none;

    &:focus {
      outline: none;
      box-shadow: 5px 6px 15px 0px ${(props) => props.theme.palette.blue._600};
      transform: translateY(-10px);
    }
  }
`;
