import React from "react";

import Fade from "react-reveal/Fade";

import Button from "../button/Button";

import { Container } from "./style";

import dashboardIcon from "../../assets/icon/dashboardIcon.svg";
import notebookImg from "../../assets/img/notebook.png";
import { links } from "../../utils";

const Dashboard = () => {
  const screenWidth = window.screen.width;

  return (
    <Container>
      {screenWidth < 1200 && (
        <div>
          <Fade>
            <img className="notebook" src={notebookImg} alt="notebookImg" />
          </Fade>
        </div>
      )}
      <div className="first">
        <Fade bottom cascadade>
          <img src={dashboardIcon} alt="dashboardIcon" />
          <h1>Dashboard</h1>
          <h2>
            Nós possuímos um kit de ferramentas que permite que empresas
            incentivem o melhor atendimento de seus funcionários e acompanhem
            esses dados em um dashboard detalhado.
          </h2>
          <Button link={links.dashboard}>Saiba mais</Button>
        </Fade>
      </div>
      {screenWidth > 1200 && (
        <div>
          <Fade>
            <img className="notebook" src={notebookImg} alt="notebookImg" />
          </Fade>
        </div>
      )}
    </Container>
  );
};

export default Dashboard;
