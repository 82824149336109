import styled from "styled-components";

export const Container = styled.div`
  z-index: 30;
  height: 60px;
  padding: 1rem;
  display: flex;
  margin: 0 auto;
  position: fixed;
  transition: 0.5s;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1200px) {
    /* Animation */
    left: ${(props) => props.scroll && "50%"};
    padding: ${(props) => props.scroll && "0"};
    transition: ${(props) => props.scroll && ".5s"};
    margin-top: ${(props) => props.scroll && "1rem"};
    width: ${(props) => (props.scroll ? "60%" : "100%")};
    border-radius: ${(props) => props.scroll && "100px"};
    backdrop-filter: ${(props) => props.scroll && "blur(4px)"};
    transform: ${(props) => props.scroll && "translateX(-50%)"};
    box-shadow: ${(props) => props.scroll && "0px 5px 10px #00000037"};
    background: ${(props) => props.scroll && props.theme.palette.black.scroll};
  }

  @media (max-width: 1200px) {
    margin: 0;
    background: ${(props) => props.theme.palette.black.main};
    border-bottom: ${(props) => `1px solid ${props.theme.palette.yellow.main}`};
    width: 100%;
    padding: 0;
    position: relative;
    
    .about {
      margin-right: 2rem;
    }

    .dashboard {
      display: none;
    }
    .home {
      display: none;
    }
    .rank {
      display: none;
    }
    .ver{
      display: none;
    }
  }
`;

export const ItemsContainer = styled.div`
  margin-right: 5rem;
  align-items: center;
  color: ${(props) => props.theme.palette.white.main};

  @media (max-width: 1200px) {
    margin-right: 0;
    /* display: flex; */
  }
`;

export const List = styled.ul`
  display: flex;

  li {
    border: none;
    list-style: none;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s;
    user-select: none;
    margin-left: 3rem;
    text-decoration: none;
    font-size: ${(props) => (props.scroll ? "11pt" : "1.1rem")};

    &:hover {
      color: ${(props) => props.theme.palette.yellow._200};
    }

    @media (max-width: 1200px) {
      margin-left: 0;
    }
  }

  .active {
    padding: 0px 20px;
    font-weight: bolder;
    color: ${(props) => props.theme.palette.yellow.main};
    border-bottom: 1px solid ${(props) => props.theme.palette.yellow.main};
  }

  @media (max-width: 1200px) {
    .active {
      padding: 0;
      font-weight: bolder;
      color: ${(props) => props.theme.palette.yellow.main};
      border-bottom: 0px solid ${(props) => props.theme.palette.yellow.main};
    }
  }
`;

export const Item = styled.li``;
