import "styled-components";

export const themes = {
  palette: {
    black: {
      main: "#1f1e1e",
      scroll: "#1f1e1eab",
      _500: "#bebebe",
    },
    white: {
      full: "#fff",
      main: "#f5f5f5",
      background: "#f9f9fc",
      transparent: "#f0f0f0",
    },
    blue: {
      scroll: "#00b0f0a6",
      main: "#00b2f0",
      _200: "#27c9ff",
      _300: "#009BCD",
      _600: "#1f9bc4",
      _700: "#283C43",
    },
    yellow: {
      scroll: "#f9b31b",
      main: "#f9b31b",
      _100: "#ffdc64",
      _200: "#ffca58",
    },
  },
};
