import styled from "styled-components";

export const FooterContaienr = styled.footer`
  margin: 0px;
  z-index: 3;
  @media screen and (max-width: 1200px) {
    .logo {
      max-width: 250px;
    }
  }
`;

export const Container = styled.div`
  background: #1f1e1e;
  margin-top: -30px;
  color: #fff;

  font-family: "Rubik";

  display: flex;
  justify-content: space-between;

  align-items: center;

  padding: 30px 70px 50px 30px;

  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    padding: 0 0 2rem 0;
    flex-direction: column;

    .right{ 
      margin-top: 4rem;
      text-align: right;
      margin-right: 1rem;
     }
     
     .contact {
       font-size: 1.4rem;
       margin: 0;
       margin-left: 1rem;
     }
  }

  img {
    max-width: 400px;
  }

  h1 {
    font-family: "Rubik", sans-serif;
    font-weight: 300;
    color: #f0f0f0;
  }

  p {
    color: #bebebe;

    a {
      color: #3dbfea;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
`;
